<template>
  <div class="card card-body shadow-sm">
    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
      <app-basic-table
        ref="basic_table"
        :table-name="$t('users.user list')"
        :filters.sync="filters"
        :setting-columns="columns"
        :endpoint-list="ENDPOINT.SALE_LIST_DETAIL_APARTMENT"
        @resetAllSearch="onResetAllSearch"
        @searched="onSearch"
      >
        <template v-slot:filters>
          <div class="row mt-2 c_filter_area mb-3">
            <div class="col-xl-4 col-lg-4 col-sm-4">
              <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-0 col-sm-0">
                  <button type="button" :disabled="isDisableButton" class="btn c_prev_month c_z_index_11" @click="getPrevMonth">
                    <i class="fa fa-angle-left c_prev_btn"></i>
                    {{ $t('sale.prevMonth') }}
                  </button>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-3 col-sm-12">
                  <month-picker-input
                    ref="month"
                    :class="['c_sale_picker', isDisableButton ? 'c_disable' : '']"
                    lang="ja"
                    :default-month="selectMonth.month"
                    :default-year="selectMonth.year"
                    @change="changeMonth"
                    :show-year="true"
                    input-pre-filled
                    date-format="%Y年%n"
                  >
                  </month-picker-input>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-0 col-sm-0">
                  <button type="button" :disabled="isDisableButton" class="btn c_prev_month c_z_index_11" @click="getNextMonth">
                    {{ $t('sale.nextMonth') }}<i class="fa fa-angle-right c_next_btn"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-4 col-md-4 col-sm-4">
              <div class="form-group">
                <label>{{ filters.apartment_id_equal.label }}</label>
                <app-select :name="filters.apartment_id_equal.name"
                            input-style="normal"
                            v-model="filters.apartment_id_equal.value"
                            :options-data="meta.apartments"
                            :disabled="isDisableApartment"
                />
              </div>
            </div>
            <div class="col-xl-4 col-md-4 col-sm-4">
              <div class="form-group">
                <label>{{ filters.purchase_type_equal.label }}</label>
                <app-select :name="filters.purchase_type_equal.name"
                            input-style="normal"
                            v-model="filters.purchase_type_equal.value"
                            :options-data="meta.purchaseTypes"
                />
              </div>
            </div>
            <div class="col-xl-4 col-md-4 col-sm-4">
              <div class="form-group">
                <label>{{ filters.customer_name_like.label }}</label>
                <app-input :name="filters.customer_name_like.name" input-style="normal"
                           v-model="filters.customer_name_like.value"
                />
              </div>
            </div>
          </div>
        </template>
        <template v-slot:table-menu-right>
          <button @click="handleBtnDownload()" class="btn btn-primary">
            {{ $t("sale.download") }}
          </button>
        </template>
        <template v-slot:body-cell-detail="props">
          <td class="app-align-middle text-center app-p-none c_w_10_percent">
            <button
              @click="handleBtnDetailClick(props.row)"
              class="btn btn-primary"
            >
              {{ $t("sale.detail2") }}
            </button>
          </td>
        </template>
        <template v-slot:body-cell-amount_facility="props">
          <td class="app-align-middle text-right">
            <p :data-original-title="props.row.amount_facility" class="app-table-p app-cell-tooltip mb-0">
              {{ convertFormatMoney(props.row.amount_facility) }}
            </p>
          </td>
        </template>
        <template v-slot:body-cell-amount_association="props">
          <td class="app-align-middle text-right">
            <p :data-original-title="props.row.amount_association" class="app-table-p app-cell-tooltip mb-0">
              {{ convertFormatMoney(props.row.amount_association) }}
            </p>
          </td>
        </template>
        <template v-slot:body-cell-amount_total="props">
          <td class="app-align-middle text-right">
            <p :data-original-title="props.row.amount_total" class="app-table-p app-cell-tooltip mb-0">
              {{ convertFormatMoney(props.row.amount_total) }}
            </p>
          </td>
        </template>
        <template v-slot:body-cell-status="props">
          <td class="app-align-middle">
            <p :data-original-title="props.row.status" :class="props.row.status == 2 ? 'app-table-p app-cell-tooltip mb-0 text-red':'app-table-p app-cell-tooltip mb-0'">
              {{ getPurchaseStatus(props.row.status) }}
            </p>
          </td>
        </template>
      </app-basic-table>
    </validation-observer>
  </div>
</template>

<script>
import {ENDPOINT} from "../../../constants/api";
import {MonthPickerInput} from 'vue-month-picker'
import moment from 'moment'

import * as echarts from 'echarts';
import AppBasicTable from "../../../components/_common/list/AppBasicTable";

export default {
  name: "SaleListPage",
  components: {
    "app-basic-table": AppBasicTable,
    MonthPickerInput,
  },
  data() {
    return {
      monthSelect: moment().format('YYYY/MM/01'),
      selectMonth: {
        month: this.$route.query['filters.month.equal'] ? moment(this.$route.query['filters.month.equal']).month() + 1 : moment().month() + 1,
        year: this.$route.query['filters.month.equal'] ? moment(this.$route.query['filters.month.equal']).year() : moment().year(),
      },
      columns: [
        {name: "customer_number", label: this.$t("sale.customer_number"), sortable: true, textAlign: 'text-center', class: 'c_w_40'},
        {name: "full_name", label: this.$t("sale.full_name"), sortable: true, class: 'c_w_40'},
        {name: "amount_facility", label: this.$t("sale.amount_facility"), sortable: true, textAlign: 'text-center', class: 'c_w_40'},
        {name: "amount_association", label: this.$t("sale.amount_association"), sortable: true, textAlign: 'text-center', class: 'c_w_40'},
        {name: "amount_total", label: this.$t("sale.amount_total"), sortable: true, class: 'c_w_40'},
        {name: "status", label: this.$t("sale.status"), sortable: true, textAlign: 'text-center', class: 'c_w_40'},
        {name: "detail", label: this.$t("sale.detail2"), textAlign: 'text-center', class: 'c_w_10_percent'},
      ],
      filters: {
        apartment_id_equal: {
          name: "apartment_id",
          condition: "equal",
          label: this.$t("customers.apartment")
        },
        purchase_type_equal: {
          name: "purchase_type",
          condition: "equal",
          label: this.$t("sale.purchase_type")
        },
        month: {
          name: "month",
          condition: "equal",
          label: this.$t("sale.month")
        },
        customer_name_like: {
          name: "customer_name",
          condition: "like",
          label: this.$t("sale.customer_name")
        },
      },
      meta: {
        apartments: [],
        purchaseTypes: [],
        purchaseStatus: [],
        customer_name: '',
      },
      urlFileDownloadCustomer: "",
      isDisableButton: false,
      isDisableApartment : false,
    };
  },
  watch: {
    'filters.apartment_id_equal.value': function(value) {
      if (value === 'all') {
        this.$router.push({name: this.ROUTES.ADMIN.SALE_LIST,query: {
            'filters.month.equal' : JSON.parse(localStorage.getItem('paramsBackToSaleList'))['filters.month.equal']
          }});
      }
    }
  },

  methods: {
    handleBtnDetailClick(entry) {
      this.$router.push({
        name: this.ROUTES.ADMIN.SALE_LIST_DETAIL_CUSTOMER,
        params: {
          customer_id: entry.customer_id, apartment_id: entry.apartment_id
        },
        query: {
          "filters.month.equal": this.$route.query['filters.month.equal'],
        }
      });
    },
    onResetAllSearch() {
      let currentMonth = moment(JSON.parse(localStorage.getItem('paramsBackToSaleList'))['filters.month.equal'])
      this.selectMonth.month = currentMonth.month() + 1
      this.selectMonth.year = currentMonth.year()
      this.filters.month.value = currentMonth.format('YYYY/MM/01')
      this.$refs.month.selectedDate = currentMonth.format('YYYY年M月')
      let queryUrl = _.cloneDeep(this.$route.query);
      if(queryUrl.prev) {
        delete queryUrl["prev"];
        localStorage.setItem('paramsFillterCurrent', JSON.stringify(queryUrl));
      } else {
        localStorage.setItem('paramsFillterCurrent', JSON.stringify(queryUrl));
      }

      let paramsBTSaleList = JSON.parse(localStorage.getItem('paramsBackToSaleList'));
      this.$router.push({name: this.ROUTES.ADMIN.SALE_LIST_DETAIL_APARTMENT,
        query : {
          "filters.apartment_id.equal": paramsBTSaleList['filters.apartment_id.equal'],
          "filters.month.equal": paramsBTSaleList['filters.month.equal'],
        }})
    },
    onSearch() {
      let queryUrl = _.cloneDeep(this.$route.query);
      if(queryUrl.prev) {
        delete queryUrl["prev"];
        localStorage.setItem('paramsFillterCurrent', JSON.stringify(queryUrl));
      } else {
        localStorage.setItem('paramsFillterCurrent', JSON.stringify(queryUrl));
      }
    },
    convertFormatMoney(amount) {

      return '￥' + amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    },
    getPurchaseStatus(status) {
      let getPurchaseText = this.meta.purchaseStatus.find(({id}) => id === status);
      if (getPurchaseText == undefined) {
        return '';
      }
      return getPurchaseText.name;
    },
    async getPrevMonth() {
      let currentMonth = this.selectMonth.year + '/' + this.selectMonth.month + '/01'
      let prevMonth = moment(currentMonth).subtract(1, 'months')
      this.selectMonth.month = prevMonth.month() + 1
      this.selectMonth.year = prevMonth.year()
      console.log(prevMonth.format('YYYY/MM/01'));
      this.filters.month.value = prevMonth.format('YYYY/MM/01')
      this.$refs.month.selectedDate = prevMonth.format('YYYY年M月')
    },
    async changeMonth(dataSelect) {
      this.selectMonth = {
        month: dataSelect.monthIndex,
        year: dataSelect.year,
      }
      let currentMonth = dataSelect.year + '/' + dataSelect.monthIndex + '/01'
      this.filters.month.value = currentMonth
      this.$refs.month.selectedDate = moment(currentMonth).format('YYYY年M月')
    },
    async getNextMonth() {
      let currentMonth = this.selectMonth.year + '/' + this.selectMonth.month + '/01'
      let nextMonth = moment(currentMonth).add(1, 'months')
      this.selectMonth.month = nextMonth.month() + 1
      this.selectMonth.year = nextMonth.year()
      console.log(nextMonth.format('YYYY/MM/01'));
      this.filters.month.value = nextMonth.format('YYYY/MM/01')
      this.$refs.month.selectedDate = nextMonth.format('YYYY年M月')
    },
    async handleBtnDownload() {

      let endpoint = ENDPOINT.SALE_LIST_DETAIL_APARTMENT_DOWNLOAD;
      let params = {}
      const filtersClone = _.cloneDeep(this.filters)
      _.forEach(filtersClone, (filter) => {
        if (filter.value && filter.value.toString().length && filter.value.toString() != 'all') {
          params[`filters[${filter.name}][${filter.condition}]`] = filter.value
        }
      })
      const res = await this.$request.get(endpoint, params)
      if (!res.hasErrors()) {
        this.urlFileDownloadCustomer = res.data.result
        var fileURL = this.urlFileDownloadCustomer;
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        document.body.appendChild(fileLink);
        fileLink.click();
      } else {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
      }
    }
  },
  mounted: function () {
    let queryUrl = _.cloneDeep(this.$route.query);
    if(queryUrl.prev=='salelist') {
      delete queryUrl["prev"];
      localStorage.setItem('paramsBackToSaleList', JSON.stringify(queryUrl));
      localStorage.setItem('paramsFillterCurrent', JSON.stringify(queryUrl));
      localStorage.setItem('isBackToSaleList',true);
    } else if(queryUrl.prev=='detailApartment') {
      delete queryUrl["prev"];
      localStorage.setItem('paramsBackToSaleList', JSON.stringify(queryUrl));
      localStorage.setItem('paramsFillterCurrent', JSON.stringify(queryUrl));
      localStorage.setItem('isBackToSaleList',false);
    } else {
      localStorage.setItem('isBackToSaleList',true);
      localStorage.setItem('paramsFillterCurrent', JSON.stringify(queryUrl));
    }
    this.$request.get(this.ENDPOINT.UI_HELPER_SALES, {apartment_id: this.filters.apartment_id_equal.value }).then(res => {
      let apartments = _.cloneDeep(res.data.apartments);
      let purchaseTypes = _.cloneDeep(res.data.purchaseTypes);
      let purchaseStatus = _.cloneDeep(res.data.purchaseStatus);
      if (res.data.apartments.length === 1) {
        this.isDisableApartment = true
        this.filters.apartment_id_equal.value = res.data.apartments[0].id
      }
      apartments.unshift({
        id: 'all',
        name: '',
      });
      this.meta = {
        apartments: apartments,
        purchaseTypes: purchaseTypes,
        purchaseStatus: purchaseStatus,
      }
    })
  },
}
</script>
<style>
.c_prev_month {
  width: 100px;
}

.c_font_size_40px {
  font-size: 40px;
}

.c_chart_area {
  height: 100%;
  border: 1px solid #d1cdcd;
}

.c_background_gray {
  background-color: #FAFAFA;
  height: 46%;
}

.c_text_align_center {
  text-align: center;
  font-weight: bold;
}

.c_border_radius_1 {
  border-radius: 10px;
}

.c_w_30_percent {
  width: 30%;
}

.c_w_10_percent {
  width: 10%;
}
</style>

